import Swiper from 'swiper';
import '../../../src/js/libs/odometer.min';

const swiper = new Swiper('.hero__carousel', {
    loop: true,
});

const locationWrapper = document.querySelector<HTMLDivElement>('.hero__event-banner--location');
const standWrapper = document.querySelector<HTMLDivElement>('.hero__event-banner--stand-location');
const dateWrapper = document.querySelector<HTMLDivElement>('.hero__event-banner--date');
const countdownWrapper = document.querySelector<HTMLDivElement>('.hero__event-banner--countdown');
let isDisplayingCountdown = true;

let wrapperCount = 0;
if (locationWrapper) {
    wrapperCount++;
}
if (standWrapper) {
    wrapperCount++;
}
if (dateWrapper) {
    wrapperCount++;
}

heroResize();
if (countdownWrapper) {
    wrapperCount++;
    const dayFigure = document.querySelector<HTMLDivElement>('.hero__event-banner--countdown_days'),
        hoursFigure = document.querySelector<HTMLDivElement>('.hero__event-banner--countdown_hours'),
        minsFigure = document.querySelector<HTMLDivElement>('.hero__event-banner--countdown_mins'),
        secsFigure = document.querySelector<HTMLDivElement>('.hero__event-banner--countdown_secs'),
        second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24,
        countDown = new Date(countdownWrapper.dataset.countdown).getTime(),
        showSecondPart = countdownWrapper.dataset.showSecondPart === 'true',
        isFlipped = countdownWrapper.dataset.isFlipped === 'true';

    let dayFigureLabel = dayFigure.nextElementSibling as HTMLDivElement,
        hoursFigureLabel = hoursFigure.nextElementSibling as HTMLDivElement,
        minsFigureLabel = minsFigure.nextElementSibling as HTMLDivElement,
        secsFigureLabel = secsFigure.nextElementSibling as HTMLDivElement;

    if (isFlipped) {
        dayFigureLabel = dayFigure.previousElementSibling as HTMLDivElement;
        hoursFigureLabel = hoursFigure.previousElementSibling as HTMLDivElement;
        minsFigureLabel = minsFigure.previousElementSibling as HTMLDivElement;
        secsFigureLabel = secsFigure.previousElementSibling as HTMLDivElement;
    }

    const showDays = (show: boolean, figure = 0) => {
        if (show) {
            dayFigure.style.display = '';
            dayFigureLabel.style.display = '';
            dayFigure.innerText = ` ${figure}`;
        } else {
            dayFigure.style.display = 'none';
            dayFigureLabel.style.display = 'none';
        }
    };

    const showHours = (show: boolean, figure = 0) => {
        if (show) {
            hoursFigure.style.display = '';
            hoursFigureLabel.style.display = '';
            hoursFigure.innerText = ` ${figure}`;
        } else {
            hoursFigure.style.display = 'none';
            hoursFigureLabel.style.display = 'none';
        }
    };

    const showMins = (show: boolean, figure = 0) => {
        if (show) {
            minsFigure.style.display = '';
            minsFigureLabel.style.display = '';
            minsFigure.innerText = ` ${figure}`;
        } else {
            minsFigure.style.display = 'none';
            minsFigureLabel.style.display = 'none';
        }
    };

    const showSecs = (show: boolean, figure = 0) => {
        if (show) {
            secsFigure.style.display = '';
            secsFigureLabel.style.display = '';
            secsFigure.innerText = ` ${figure}`;
        } else {
            secsFigure.style.display = 'none';
            secsFigureLabel.style.display = 'none';
        }
    };

    const countDownTimer = setInterval(function () {
        const now = new Date().getTime(),
            distance = countDown - now;

        if (distance < 0) {
            countdownWrapper.style.display = 'none';
            isDisplayingCountdown = false;
            clearInterval(countDownTimer);
            wrapperCount--;
        } else {
            const daysToGo = Math.floor(distance / day);
            const hoursToGo = Math.floor((distance % day) / hour);
            const minsToGo = Math.floor((distance % hour) / minute);
            const secsToGo = Math.floor((distance % minute) / second);

            if (daysToGo > 0) {
                showDays(true, daysToGo);
                showHours(showSecondPart, hoursToGo);
                showMins(false);
                showSecs(false);
            } else {
                showDays(false);
                if (hoursToGo > 0) {
                    showHours(true, hoursToGo);
                    showMins(showSecondPart, minsToGo);
                    showSecs(false);
                } else {
                    showHours(false);
                    if (minsToGo > 0) {
                        showMins(true, minsToGo);
                        showSecs(showSecondPart, secsToGo);
                    } else {
                        showMins(false);
                        showSecs(secsToGo > 0, secsToGo);
                    }
                }
            }
        }
    }, 0);
}

if (wrapperCount === 3 || (wrapperCount < 3 && isDisplayingCountdown)) {
    if (wrapperCount < 3) {
        if (locationWrapper) {
            locationWrapper.style.borderRight = '0px';
        } else if (standWrapper) {
            standWrapper.style.borderRight = '0px';
        } else if (dateWrapper) {
            dateWrapper.style.borderRight = '0px';
        }
    }

    const eventBanner = document.querySelector<HTMLDivElement>('.hero__event-banner');
    if (eventBanner) {
        eventBanner.classList.remove('hero__event-banner');
        eventBanner.classList.add('hero__event-banner-md');
    }
} else if (wrapperCount < 3) {
    if (isDisplayingCountdown && locationWrapper) {
        locationWrapper.style.borderRight = '0px';
    } else if (isDisplayingCountdown && standWrapper) {
        standWrapper.style.borderRight = '0px';
    } else if (isDisplayingCountdown && dateWrapper) {
        dateWrapper.style.borderRight = '0px';
    }
    const eventBanner = document.querySelector<HTMLDivElement>('.hero__event-banner');
    if (eventBanner) {
        eventBanner.classList.remove('hero__event-banner');
        eventBanner.classList.add('hero__event-banner-sm');
    }
}

const heroModal = document.querySelector('.hero__modal') as HTMLDivElement;

if (heroModal) {
    const openModal = document.querySelector('.hero__side-link--secondary') as HTMLDivElement;
    const closeModal = document.querySelector('.hero__modal-close') as HTMLDivElement;
    const modalBackdrop = document.querySelector('.hero__modal-backdrop') as HTMLDivElement;

    const handleModalOpen = (e) => {
        e.preventDefault();

        heroModal.style.opacity = '1';
        heroModal.style.visibility = 'visible';
    };

    const handleModalClose = (e) => {
        e.preventDefault();

        heroModal.style.opacity = '0';
        heroModal.style.visibility = 'hidden';
    };

    if (closeModal) {
        closeModal.addEventListener('click', (e: Event) => handleModalClose(e));
    }
    if (openModal) {
        openModal.addEventListener('click', (e: Event) => handleModalOpen(e));
    }
    if (modalBackdrop) {
        modalBackdrop.addEventListener('click', (e: Event) => handleModalClose(e));
    }
}

function heroResize() {
    const eventHero = document.querySelector('.hero__overlay');
    if (eventHero == null) return;
    
    const height = (eventHero.clientHeight + 80).toString() + 'px';

    const activeSlide = document.querySelector<HTMLElement>('.swiper-slide-active');
    if (activeSlide == null) return;

    const heroEvent = activeSlide.querySelector<HTMLElement>('.hero__event-page');
    if (heroEvent == null) return;

    const sliderHeight = heroEvent.nextElementSibling as HTMLDivElement;
    if (window.innerWidth < 1200) {
        sliderHeight.style.height = height;
    } else {
        sliderHeight.style.removeProperty('height');
    }
}
window.addEventListener('resize', (event) => {
    heroResize();
});
